import { createSlice } from '@reduxjs/toolkit';
import { ConnectionType } from 'connection';
import { SupportedLocale } from 'constants/locales';

import { DEFAULT_DEADLINE_FROM_NOW } from '../../constants/misc';
import { updateVersion } from '../global/actions';
import { SerializedPair, SerializedToken } from './types';

const currentTimestamp = () => new Date().getTime();

export interface UserState {
  selectedWallet?: ConnectionType;

  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number;

  matchesDarkMode: boolean; // whether the dark mode media query matches

  userDarkMode: boolean | null; // the user's choice for dark mode or light mode
  userLocale: SupportedLocale | null;

  userExpertMode: boolean;

  userClientSideRouter: boolean; // whether routes should be calculated with the client side router only

  // hides closed (inactive) positions across the app
  userHideClosedPositions: boolean;

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number | 'auto';
  userSlippageToleranceHasBeenMigratedToAuto: boolean; // temporary flag for migration status

  // deadline set by user in minutes, used in all txns
  userDeadline: number;

  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken;
    };
  };

  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair;
    };
  };

  timestamp: number;
  URLWarningVisible: boolean;

  // undefined means has not gone through A/B split yet
  showSurveyPopup: boolean | undefined;

  showDonationLink: boolean;
}

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`;
}

export const initialState: UserState = {
  selectedWallet: undefined,
  matchesDarkMode: false,
  userDarkMode: null,
  userExpertMode: false,
  userLocale: null,
  userClientSideRouter: false,
  userHideClosedPositions: false,
  userSlippageTolerance: 'auto',
  userSlippageToleranceHasBeenMigratedToAuto: true,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  tokens: {},
  pairs: {},
  timestamp: currentTimestamp(),
  URLWarningVisible: true,
  showSurveyPopup: undefined,
  showDonationLink: true
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateSelectedWallet(state, { payload: { wallet } }) {
      state.selectedWallet = wallet;
    },
    updateUserDarkMode(state, action) {
      state.userDarkMode = action.payload.userDarkMode;
      state.timestamp = currentTimestamp();
    },
    updateMatchesDarkMode(state, action) {
      state.matchesDarkMode = action.payload.matchesDarkMode;
      state.timestamp = currentTimestamp();
    },
    updateUserExpertMode(state, action) {
      state.userExpertMode = action.payload.userExpertMode;
      state.timestamp = currentTimestamp();
    },
    updateUserLocale(state, action) {
      state.userLocale = action.payload.userLocale;
      state.timestamp = currentTimestamp();
    },
    updateUserSlippageTolerance(state, action) {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
      state.timestamp = currentTimestamp();
    },
    updateUserDeadline(state, action) {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    },
    updateUserClientSideRouter(state, action) {
      state.userClientSideRouter = action.payload.userClientSideRouter;
    },
    updateHideClosedPositions(state, action) {
      state.userHideClosedPositions = action.payload.userHideClosedPositions;
    },
    updateShowSurveyPopup(state, action) {
      state.showSurveyPopup = action.payload.showSurveyPopup;
    },
    updateShowDonationLink(state, action) {
      state.showDonationLink = action.payload.showDonationLink;
    },
    addSerializedToken(state, { payload: { serializedToken } }) {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[serializedToken.chainId] =
        state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] =
        serializedToken;
      state.timestamp = currentTimestamp();
    },
    removeSerializedToken(state, { payload: { address, chainId } }) {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[chainId] = state.tokens[chainId] || {};
      delete state.tokens[chainId][address];
      state.timestamp = currentTimestamp();
    },
    addSerializedPair(state, { payload: { serializedPair } }) {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const chainId = serializedPair.token0.chainId;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][
          pairKey(serializedPair.token0.address, serializedPair.token1.address)
        ] = serializedPair;
      }
      state.timestamp = currentTimestamp();
    },
    removeSerializedPair(
      state,
      { payload: { chainId, tokenAAddress, tokenBAddress } }
    ) {
      if (state.pairs[chainId]) {
        // just delete both keys if either exists
        delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)];
        delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)];
      }
      state.timestamp = currentTimestamp();
    }
  },
  extraReducers: builder => {
    builder.addCase(updateVersion, state => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (
        typeof state.userSlippageTolerance !== 'number' ||
        !Number.isInteger(state.userSlippageTolerance) ||
        state.userSlippageTolerance < 0 ||
        state.userSlippageTolerance > 5000
      ) {
        state.userSlippageTolerance = 'auto';
      } else {
        if (
          !state.userSlippageToleranceHasBeenMigratedToAuto &&
          [10, 50, 100].indexOf(state.userSlippageTolerance) !== -1
        ) {
          state.userSlippageTolerance = 'auto';
          state.userSlippageToleranceHasBeenMigratedToAuto = true;
        }
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (
        typeof state.userDeadline !== 'number' ||
        !Number.isInteger(state.userDeadline) ||
        state.userDeadline < 60 ||
        state.userDeadline > 180 * 60
      ) {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
      }

      state.lastUpdateVersionTimestamp = currentTimestamp();
    });
  }
});

export const {
  updateSelectedWallet,
  addSerializedPair,
  addSerializedToken,
  removeSerializedPair,
  removeSerializedToken,
  updateHideClosedPositions,
  updateMatchesDarkMode,
  updateShowDonationLink,
  updateShowSurveyPopup,
  updateUserClientSideRouter,
  updateUserDarkMode,
  updateUserDeadline,
  updateUserExpertMode,
  updateUserLocale,
  updateUserSlippageTolerance
} = userSlice.actions;
export default userSlice.reducer;
