import { transparentize } from 'polished';
import { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import Popover, { PopoverProps } from '../Popover';

export const TooltipContainer = styled.div`
  max-width: 256px;
  padding: 0.6rem 1rem;
  font-weight: 400;
  word-break: break-word;

  background: ${({ theme }) => theme.deprecated_bg0};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.deprecated_bg2};
  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.9, theme.shadow1)};
`;

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: ReactNode;
  disableHover?: boolean; // disable the hover and content display
}

interface TooltipContentProps extends Omit<PopoverProps, 'content'> {
  content: ReactNode;
  onOpen?: () => void;
  // whether to wrap the content in a `TooltipContainer`
  wrap?: boolean;
  disableHover?: boolean; // disable the hover and content display
}

export default function Tooltip({ text, ...rest }: TooltipProps) {
  return (
    <Popover
      content={text && <TooltipContainer>{text}</TooltipContainer>}
      {...rest}
    />
  );
}

function TooltipContent({
  content,
  wrap = false,
  ...rest
}: TooltipContentProps) {
  return (
    <Popover
      content={wrap ? <TooltipContainer>{content}</TooltipContainer> : content}
      {...rest}
    />
  );
}

/** Standard text tooltip. */
export function MouseoverTooltip({
  text,
  disableHover,
  children,
  ...rest
}: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => setShow(true), [setShow]);
  const close = useCallback(() => setShow(false), [setShow]);
  const noOp = () => null;
  return (
    <Tooltip {...rest} show={show} text={disableHover ? null : text}>
      <div
        onMouseEnter={disableHover ? noOp : open}
        onMouseLeave={disableHover ? noOp : close}
      >
        {children}
      </div>
    </Tooltip>
  );
}

/** Tooltip that displays custom content. */
export function MouseoverTooltipContent({
  content,
  children,
  onOpen: openCallback = undefined,
  disableHover,
  ...rest
}: Omit<TooltipContentProps, 'show'>) {
  const [show, setShow] = useState(false);
  const open = useCallback(() => {
    setShow(true);
    openCallback?.();
  }, [openCallback]);
  const close = useCallback(() => setShow(false), [setShow]);
  return (
    <TooltipContent
      {...rest}
      show={show}
      content={disableHover ? null : content}
    >
      <div
        style={{ display: 'inline-block', lineHeight: 0, padding: '0.25rem' }}
        onMouseEnter={open}
        onMouseLeave={close}
      >
        {children}
      </div>
    </TooltipContent>
  );
}
