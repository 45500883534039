import { Currency } from '@uniswap/sdk-core';
import { TokenInfo } from '@uniswap/token-lists';

import AssetLogo, { AssetLogoBaseProps } from '../Logo/AssetLogo';

export default function CurrencyLogo(
  props: AssetLogoBaseProps & {
    currency?: Currency | null;
  }
) {
  return (
    <AssetLogo
      isNative={props.currency?.isNative}
      chainId={props.currency?.chainId}
      address={props.currency?.wrapped.address}
      symbol={props.symbol ?? props.currency?.symbol}
      backupImg={(props.currency as TokenInfo)?.logoURI}
      hideL2Icon={props.hideL2Icon ?? true}
      {...props}
    />
  );
}
