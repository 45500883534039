import * as Switch from '@radix-ui/react-switch';
import styled from 'styled-components/macro';

const StyledToggle = styled.div`
  display: flex;
  column-gap: 4px;
`;

const StyledToggleLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
`;

const StyledToggleRoot = styled(Switch.Root)`
  padding: 1px;
  width: 2.5rem;
  height: 1.25rem;
  border-radius: 9999px;
  border: 1px solid #7a7a7a;
  cursor: pointer;

  &[data-state='checked'] {
    border-color: ${({ theme }) => theme.oneExSecondaryMain};
  }
`;

const StyledToggleThumb = styled(Switch.Thumb)`
  display: block;
  transition-property: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 1rem;
  height: 100%;
  border-radius: 9999px;
  background-color: #7a7a7a;

  &[data-state='checked'] {
    transform: translateX(1.25rem);
    background-color: ${({ theme }) => theme.oneExSecondaryMain};
  }
`;

const Toggle = ({
  label,
  onChange
}: {
  label: React.ReactNode;
  onChange: (checked: boolean) => void;
}) => (
  <StyledToggle>
    <StyledToggleLabel>{label}</StyledToggleLabel>
    <StyledToggleRoot onCheckedChange={onChange}>
      <StyledToggleThumb />
    </StyledToggleRoot>
  </StyledToggle>
);

export default Toggle;
