import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import MobileBottomBarLink from './MobileBottomBarLink';

const StyledMobileBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 10;
  padding: 0 4rem;
  background-color: ${({ theme }) => theme.oneExNeutral2};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: block;
  `};
`;

const StyledMobileBottomBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  margin: auto;
  height: 3rem;
`;

const MobileBottomBar = () => {
  return (
    <StyledMobileBottomBar>
      <StyledMobileBottomBarContainer>
        <MobileBottomBarLink href="trade">
          <Trans>Trade</Trans>
        </MobileBottomBarLink>
        <MobileBottomBarLink href="pool">
          <Trans>Pool</Trans>
        </MobileBottomBarLink>
      </StyledMobileBottomBarContainer>
    </StyledMobileBottomBar>
  );
};

export default MobileBottomBar;
