/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from 'react';
import type {} from 'styled-jsx';
import { useIsDarkMode } from 'state/user/hooks';
import styled, { useTheme } from 'styled-components/macro';
import { darkTheme, lightTheme } from './theme';

const StyledWidgetContainer = styled.div`
  max-width: 400px;
  margin-top: 2rem;
  background-color: transparent;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    max-width: 100%;
    margin-top: 0;
  `};
`;

const Bridge = () => {
  const isDark = useIsDarkMode();
  const theme = useTheme();

  const stringDarkTheme = JSON.stringify(darkTheme);
  const stringLightTheme = JSON.stringify(lightTheme);

  const widgetTheme = useMemo(() => {
    return isDark ? stringDarkTheme : stringLightTheme;
  }, [isDark, stringDarkTheme, stringLightTheme]);

  return (
    <StyledWidgetContainer>
      <style jsx global>{`
        .MuiScopedCssBaseline-root {
          position: relative;
          background-color: transparent;
        }
        .MuiPaper-root .MuiPaper-root {
          background-color: ${theme.oneExNeutral1};
        }
        .StgHeader {
          border-bottom: 2px solid ${theme.oneExNeutral2};
        }
        .StgHeader .MuiTypography-subtitle1 {
          font-weight: 600;
        }
        /* .MuiScopedCssBaseline-root .StgMaxButton {
          border-color: ${lightTheme.palette.primary.main} !important;
          background-color: transparent;
          padding: 11px 16px;
          font-size: 13px;
        }
        .MuiFormLabel-root.Mui-focused {
          color: ${lightTheme.palette.text.primary} !important;
        } */
      `}</style>
      {/* @ts-ignore */}
      <stargate-widget theme={widgetTheme} />
    </StyledWidgetContainer>
  );
};

export default Bridge;
