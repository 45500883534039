import TradeNavbar from 'components/TradeNavbar';
import { Navigate, Outlet, useMatch } from 'react-router-dom';

const Trade = () => {
  const exactTradeRoute = useMatch('/trade');

  if (exactTradeRoute) {
    return <Navigate to="swap" />;
  }

  return (
    <>
      <TradeNavbar />
      <Outlet />
    </>
  );
};

export default Trade;
