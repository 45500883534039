import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import Web3Status from '../Web3Status';
import NavbarLink from './NavbarLink';
import { ChainSelector } from './ChainSelector';
import { ReactComponent as Logo } from 'assets/logos/logo_light.svg';

const StyledNavbar = styled.nav`
  position: relative;
  display: flex;
  z-index: 10;
  padding: 0 4rem;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  background-color: ${({ theme }) => theme.oneExNeutral2};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    padding: 0 0.75rem;
    height: 5rem;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  `};
`;

const StyledLogo = styled(Logo)`
  margin-right: 16px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 80px;
  `};
`;

const StyledNavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 6rem;
  /* left: 45%; */
`;

const StyledNavbarInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3.5rem;
`;

const StyledAccountChainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
`;

const Navbar = () => {
  return (
    <StyledNavbar>
      <StyledLogo />
      <StyledNavbarContainer>
        <StyledNavbarInnerContainer>
          <NavbarLink href="trade">
            <Trans>Trade</Trans>
          </NavbarLink>
          <NavbarLink href="pool">
            <Trans>Pool</Trans>
          </NavbarLink>
        </StyledNavbarInnerContainer>
      </StyledNavbarContainer>
      <StyledAccountChainContainer>
        <Web3Status />
        <ChainSelector />
      </StyledAccountChainContainer>
    </StyledNavbar>
  );
};

export default Navbar;
