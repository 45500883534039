import { useState } from 'react';
import styled from 'styled-components/macro';
// import Chart from './Chart';
import SwapBox from './SwapBox';
import RecentTransactions from './RecentTransactions';
import { useIsMobile } from 'nft/hooks';
import TradingViewChart from 'components/TradingviewChart/TradingviewChart';

const StyledSwap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1 0%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    width: 100%;
  `};
`;

const StyledMain = styled.main<{ isAdvanced: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* grid-template-columns: ${({ isAdvanced }) =>
    isAdvanced ? 'min-content 1fr' : '1fr'};
  grid-template-rows: ${({ isAdvanced }) =>
    isAdvanced ? 'repeat(2, min-content)' : 'min-content'}; */
  padding: 1.5rem 4rem;
  margin: 0 auto;
  column-gap: 2rem;
  max-width: 87rem;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    padding: 0;
    margin: 0;
  `};
`;

const StyledAdvancedContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  width: 90vw;
  max-width: 800px;
`;

// const StyledSwapMainContainer = styled.div`
//   align-self: flex-start;

//   ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
//     width: 100%;
//   `};
// `;

const Swap = () => {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const isMobile = useIsMobile();

  const advancedContent = (
    <>
      <TradingViewChart />
      <RecentTransactions isMobile={isMobile} />
    </>
  );

  return (
    <StyledSwap>
      <StyledMain isAdvanced={isAdvanced}>
        {/* <StyledSwapMainContainer> */}
        <SwapBox setIsAdvanced={setIsAdvanced} />
        {/* </StyledSwapMainContainer> */}
        {isAdvanced ? (
          <StyledAdvancedContainer>{advancedContent}</StyledAdvancedContainer>
        ) : (
          isMobile && advancedContent
        )}
      </StyledMain>
    </StyledSwap>
  );
};

export default Swap;
