import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledNavbarItem = styled(NavLink)`
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.25rem 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.oneExNeutral4};

  &.active {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.oneExPrimaryMainLight};
    color: ${({ theme }) => theme.oneExPrimaryHigh};
    border-radius: 0;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: none;
  `};
`;

const NavbarLink = ({
  href,
  children
}: {
  href: string;
  children: ReactNode;
}) => {
  return <StyledNavbarItem to={href}>{children}</StyledNavbarItem>;
};

export default NavbarLink;
