import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RelayEnvironmentProvider } from 'react-relay';
import App from './App';
import ThemeProvider, { ThemedGlobalStyle } from './theme';
import './index.css';
import store from './state';
import { LanguageProvider } from './i18n';
import { FeatureFlagsProvider } from 'featureFlags';
import Web3Provider from './components/Web3Provider';
import RelayEnvironment from 'graphql/data/RelayEnvironment';
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber';
import { MulticallUpdater } from 'lib/state/multicall';
import ApplicationUpdater from './state/application/updater';
import ListsUpdater from './state/lists/updater';
import LogsUpdater from './state/logs/updater';
import TransactionUpdater from './state/transactions/updater';
import UserUpdater from './state/user/updater';
import RadialGradientByChainUpdater from './theme/RadialGradientByChainUpdater';

const Updaters = () => {
  return (
    <>
      <RadialGradientByChainUpdater />
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <FeatureFlagsProvider>
        <HashRouter>
          <LanguageProvider>
            <Web3Provider>
              <RelayEnvironmentProvider environment={RelayEnvironment}>
                <BlockNumberProvider>
                  <Updaters />
                  <ThemeProvider>
                    <ThemedGlobalStyle />
                    <App />
                  </ThemeProvider>
                </BlockNumberProvider>
              </RelayEnvironmentProvider>
            </Web3Provider>
          </LanguageProvider>
        </HashRouter>
      </FeatureFlagsProvider>
    </Provider>
  </React.StrictMode>
);
