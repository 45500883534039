import { Trans } from '@lingui/macro';
import { Trace, TraceEvent } from '@uniswap/analytics';
import {
  BrowserEvent,
  ElementName,
  EventName,
  PageName
} from '@uniswap/analytics-events';
import { useWeb3React } from '@web3-react/core';
import {
  ButtonGray,
  ButtonPrimary,
  ButtonPrimaryLow,
  ButtonText
} from 'components/Button';
import { AutoColumn } from 'components/Column';
import { FlyoutAlignment, NewMenu } from 'components/Menu';
import PositionList from 'components/PositionList';
import { RowBetween, RowFixed } from 'components/Row';
import { SwitchLocaleLink } from 'components/SwitchLocaleLink';
import { isSupportedChain } from 'constants/chains';
import { useV3Positions } from 'hooks/useV3Positions';
import {
  AlertTriangle,
  BookOpen,
  ChevronDown,
  ChevronsRight,
  Inbox,
  Layers,
  PlusCircle
} from 'react-feather';
import { Link } from 'react-router-dom';
import { useToggleWalletModal } from 'state/application/hooks';
import { useUserHideClosedPositions } from 'state/user/hooks';
import styled, { css, useTheme } from 'styled-components/macro';
import { HideSmall, ThemedText } from 'theme';
import { PositionDetails } from 'types/position';

import { V2_FACTORY_ADDRESSES } from '../../constants/addresses';
import CTACards from './CTACards';
import { LoadingRows } from './styleds';

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 870px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
  `};

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`;

const StyledPoolContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.oneExNeutral2};
  border-radius: 16px;
`;

const TitleRow = styled(RowBetween)`
  background-color: ${({ theme }) => theme.oneExNeutralOpacity};
  padding: 12px 16px;
  border-radius: 16px 16px 0 0;
  border-bottom: 2px solid ${({ theme }) => theme.oneExNeutral2};
  color: ${({ theme }) => theme.deprecated_text2};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  `};
`;

const ButtonRow = styled(RowFixed)`
  & > *:not(:last-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row-reverse;
  `};
`;

const Menu = styled(NewMenu)`
  margin-left: 0;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 49%;
    right: 0px;
  `};

  a {
    width: 100%;
  }
`;

const MenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
`;

const MoreOptionsButton = styled(ButtonGray)`
  border-radius: 12px;
  flex: 1 1 auto;
  padding: 6px 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.deprecated_bg0};
  margin-right: 8px;
`;

const MoreOptionsText = styled(ThemedText.DeprecatedBody)`
  align-items: center;
  display: flex;
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

const NetworkIcon = styled(AlertTriangle)`
  ${IconStyle}
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  border-radius: 12px;
  padding: 8px 16px;
  width: fit-content;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex: 1 1 auto;
    width: 100%;
  `};
`;

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.oneExNeutralOpacity};
  /* border: 1px solid ${({ theme }) => theme.backgroundOutline}; */
  margin: 0 16px 16px 16px;
  /* padding: 0; */
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
`;

function PositionsLoadingPlaceholder() {
  return (
    <LoadingRows>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </LoadingRows>
  );
}

function WrongNetworkCard() {
  const theme = useTheme();

  return (
    <>
      <PageWrapper>
        <StyledPoolContainer>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow padding={'0'}>
                <ThemedText.LargeHeader>
                  <Trans>Pools</Trans>
                </ThemedText.LargeHeader>
              </TitleRow>

              <MainContentWrapper>
                <ErrorContainer>
                  <ThemedText.DeprecatedBody
                    color={theme.deprecated_text3}
                    textAlign="center"
                  >
                    <NetworkIcon strokeWidth={1.2} />
                    <div data-testid="pools-unsupported-err">
                      <Trans>Your connected network is unsupported.</Trans>
                    </div>
                  </ThemedText.DeprecatedBody>
                </ErrorContainer>
              </MainContentWrapper>
            </AutoColumn>
          </AutoColumn>
        </StyledPoolContainer>
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  );
}

const Pool = () => {
  const { account, chainId } = useWeb3React();
  const toggleWalletModal = useToggleWalletModal();

  const theme = useTheme();
  const [userHideClosedPositions, setUserHideClosedPositions] =
    useUserHideClosedPositions();

  const { positions, loading: positionsLoading } = useV3Positions(account);

  if (!isSupportedChain(chainId)) {
    return <WrongNetworkCard />;
  }

  const [openPositions, closedPositions] = positions?.reduce<
    [PositionDetails[], PositionDetails[]]
  >(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p);
      return acc;
    },
    [[], []]
  ) ?? [[], []];

  const filteredPositions = [
    ...openPositions,
    ...(userHideClosedPositions ? [] : closedPositions)
  ];
  const showConnectAWallet = Boolean(!account);
  const showV2Features = Boolean(V2_FACTORY_ADDRESSES[chainId]);

  const menuItems = [
    {
      content: (
        <MenuItem>
          <Trans>Create a pool</Trans>
          <PlusCircle size={16} />
        </MenuItem>
      ),
      link: '/add/ETH',
      external: false
    },
    {
      content: (
        <MenuItem>
          <Trans>Migrate</Trans>
          <ChevronsRight size={16} />
        </MenuItem>
      ),
      link: '/migrate/v2',
      external: false
    },
    {
      content: (
        <MenuItem>
          <Trans>V2 liquidity</Trans>
          <Layers size={16} />
        </MenuItem>
      ),
      link: '/pool/v2',
      external: false
    },
    {
      content: (
        <MenuItem>
          <Trans>Learn</Trans>
          <BookOpen size={16} />
        </MenuItem>
      ),
      link: 'https://docs.uniswap.org/',
      external: true
    }
  ];

  return (
    <Trace page={PageName.POOL_PAGE} shouldLogImpression>
      <>
        <PageWrapper>
          <StyledPoolContainer>
            <AutoColumn gap="lg" justify="center">
              <AutoColumn gap="lg" style={{ width: '100%' }}>
                <TitleRow padding={'0'}>
                  <ThemedText.HeadlineSmall>
                    <Trans>Pools</Trans>
                  </ThemedText.HeadlineSmall>
                  <ButtonRow>
                    {/* {showV2Features && (
                    <Menu
                      menuItems={menuItems}
                      flyoutAlignment={FlyoutAlignment.LEFT}
                      ToggleUI={(props: any) => (
                        <MoreOptionsButton {...props}>
                          <MoreOptionsText>
                            <Trans>More</Trans>
                            <ChevronDown size={15} />
                          </MoreOptionsText>
                        </MoreOptionsButton>
                      )}
                    />
                  )} */}
                    <ResponsiveButtonPrimary
                      data-cy="join-pool-button"
                      id="join-pool-button"
                      as={Link}
                      to="/add/ETH"
                    >
                      + <Trans>New Position</Trans>
                    </ResponsiveButtonPrimary>
                  </ButtonRow>
                </TitleRow>

                <MainContentWrapper>
                  {positionsLoading ? (
                    <PositionsLoadingPlaceholder />
                  ) : filteredPositions &&
                    closedPositions &&
                    filteredPositions.length > 0 ? (
                    <PositionList
                      positions={filteredPositions}
                      setUserHideClosedPositions={setUserHideClosedPositions}
                      userHideClosedPositions={userHideClosedPositions}
                    />
                  ) : (
                    <ErrorContainer>
                      <ThemedText.DeprecatedBody
                        color={theme.deprecated_text3}
                        textAlign="center"
                      >
                        <InboxIcon strokeWidth={1} />
                        <div>
                          <Trans>
                            Your active liquidity positions will appear here.
                          </Trans>
                        </div>
                      </ThemedText.DeprecatedBody>
                      {!showConnectAWallet && closedPositions.length > 0 && (
                        <ButtonText
                          style={{
                            marginTop: '.5rem'
                          }}
                          onClick={() =>
                            setUserHideClosedPositions(!userHideClosedPositions)
                          }
                        >
                          <Trans>Show closed positions</Trans>
                        </ButtonText>
                      )}
                      {showConnectAWallet && (
                        <TraceEvent
                          events={[BrowserEvent.onClick]}
                          name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                          properties={{
                            received_swap_quote: false
                          }}
                          element={ElementName.CONNECT_WALLET_BUTTON}
                        >
                          <ButtonPrimaryLow
                            style={{
                              marginTop: '2rem',
                              marginBottom: '2rem',
                              padding: '8px 16px'
                            }}
                            onClick={toggleWalletModal}
                          >
                            <Trans>Connect wallet</Trans>
                          </ButtonPrimaryLow>
                        </TraceEvent>
                      )}
                    </ErrorContainer>
                  )}
                </MainContentWrapper>
                {/* <HideSmall>
                  <CTACards />
                </HideSmall> */}
              </AutoColumn>
            </AutoColumn>
          </StyledPoolContainer>
        </PageWrapper>
        <SwitchLocaleLink />
      </>
    </Trace>
  );
};

export default Pool;
