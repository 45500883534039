import styled from 'styled-components/macro';

const StyledBackgroundContainer = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => theme.oneExNeutral1};
  opacity: 60%;
  z-index: -1;
`;

const StyledBackgroundItem = styled.div<{
  verticalPosition: string;
  horizontalPosition: string;
  $color: number;
}>`
  position: absolute;
  top: ${({ verticalPosition }) =>
    verticalPosition === 'top' ? '10%' : '50%'};
  left: ${({ horizontalPosition, verticalPosition }) =>
    horizontalPosition === 'left' &&
    (verticalPosition === 'top' ? '20%' : '10%')};
  right: ${({ horizontalPosition, verticalPosition }) =>
    horizontalPosition === 'right' &&
    (verticalPosition === 'bottom' ? '10%' : '20%')};
  width: 24rem;
  height: 24rem;
  border-radius: 9999px;
  filter: blur(200px);
  background-color: ${({ theme, $color }) =>
    $color === 1 ? theme.oneExBlur1 : theme.oneExBlur2};
`;

const ColorfulBackground = () => {
  return (
    <StyledBackgroundContainer>
      <StyledBackgroundItem
        verticalPosition="bottom"
        horizontalPosition="left"
        $color={1}
      />
      <StyledBackgroundItem
        verticalPosition="top"
        horizontalPosition="left"
        $color={2}
      />
      <StyledBackgroundItem
        verticalPosition="top"
        horizontalPosition="right"
        $color={1}
      />
      <StyledBackgroundItem
        verticalPosition="bottom"
        horizontalPosition="right"
        $color={2}
      />
    </StyledBackgroundContainer>
  );
};

export default ColorfulBackground;
