import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import TradeNavbarButton from './TradeNavbarButton';

export const StyledTradeNavbar = styled.button`
  display: flex;
  height: 3.5rem;
  width: 100%;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  background-color: ${({ theme }) => theme.oneExNeutral1};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    height: 2.5rem;
  `};
`;

const TradeNavbar = () => {
  return (
    <StyledTradeNavbar>
      <TradeNavbarButton href="swap">
        <Trans>Swap</Trans>
      </TradeNavbarButton>
      <TradeNavbarButton href="bridge">
        <Trans>Bridge</Trans>
      </TradeNavbarButton>
    </StyledTradeNavbar>
  );
};

export default TradeNavbar;
