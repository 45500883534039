import { Trans } from '@lingui/macro';
import { Percent } from '@uniswap/sdk-core';
import Toggle from 'components/UI/Toggle';
import { useIsMobile } from 'nft/hooks';
import { useToggleChart, useToggleTransactions } from 'state/application/hooks';
import styled from 'styled-components/macro';

import { ThemedText } from '../../theme';
import { RowBetween, RowFixed } from '../Row';
import SettingsTab from '../Settings';

import { ReactComponent as HistoryIcon } from 'assets/svg/history.svg';
import { ReactComponent as ChartIcon } from 'assets/svg/chart.svg';

const StyledSwapHeader = styled.div`
  padding: 0.5rem 1rem;
  margin-bottom: 32px;
  width: 100%;
  color: ${({ theme }) => theme.deprecated_text2};
  border-bottom: 2px solid ${({ theme }) => theme.oneExNeutral2};
`;

const StyledAdvancedButtonsContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

const StyledAdvancedButton = styled.button`
  height: 24px;
`;

export default function SwapHeader({
  allowedSlippage,
  setIsAdvanced
}: {
  allowedSlippage: Percent;
  setIsAdvanced: React.Dispatch<boolean>;
}) {
  const isMobile = useIsMobile();

  const toggleTransactions = useToggleTransactions();
  const toggleChart = useToggleChart();

  return (
    <StyledSwapHeader>
      <RowBetween>
        <RowFixed>
          <ThemedText.DeprecatedBlack
            fontWeight={500}
            fontSize={16}
            style={{ marginRight: '8px' }}
          >
            <Trans>Swap</Trans>
          </ThemedText.DeprecatedBlack>
        </RowFixed>

        <RowFixed>
          {!isMobile ? (
            <Toggle
              label={<Trans>Advanced</Trans>}
              onChange={checked => setIsAdvanced(checked ? true : false)}
            />
          ) : (
            <StyledAdvancedButtonsContainer>
              <StyledAdvancedButton
                style={{ height: '24px' }}
                onClick={toggleTransactions}
              >
                <HistoryIcon />
              </StyledAdvancedButton>
              {/* <StyledAdvancedButton
                style={{ height: '24px' }}
                onClick={toggleChart}
              >
                <ChartIcon />
              </StyledAdvancedButton> */}
            </StyledAdvancedButtonsContainer>
          )}
          <SettingsTab placeholderSlippage={allowedSlippage} />
        </RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  );
}
