import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const StyledTradeNavbarLink = styled(NavLink)`
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  color: ${({ theme }) => theme.oneExNeutral4};

  &.active {
    background-color: ${({ theme }) => theme.oneExPrimaryLow};
    border: 1px solid ${({ theme }) => theme.oneExPrimaryMainLight};

    ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
      background-color: transparent;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.oneExPrimaryMainLight};
      color: ${({ theme }) => theme.oneExPrimaryMainLight};
      border-radius: 0;
    `};
  }
`;

const TradeNavbarButton = ({
  children,
  href
}: {
  children: ReactNode;
  href: string;
}) => {
  return <StyledTradeNavbarLink to={href}>{children}</StyledTradeNavbarLink>;
};

export default TradeNavbarButton;
