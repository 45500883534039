import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  initializeAnalytics,
  OriginApplication,
  sendAnalyticsEvent,
  Trace,
  user
} from '@uniswap/analytics';
import {
  CustomUserProperties,
  EventName,
  getBrowser,
  PageName
} from '@uniswap/analytics-events';
// import styled from 'styled-components/macro';
import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals';
import Loader from 'components/Loader';
import { useFeatureFlagsIsLoaded } from 'featureFlags';
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader';
import { useIsDarkMode, useIsExpertMode } from 'state/user/hooks';
import { SpinnerSVG } from 'theme/components';
// import { Z_INDEX } from 'theme/zIndex';

import { useAnalyticsReporter } from './components/analytics';
// import Polling from './components/Polling';
// import Popups from './components/Popups';
import DarkModeQueryParamReader from './theme/DarkModeQueryParamReader';
// import AddLiquidity from './AddLiquidity';
// import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects';
// import Pool from './Pool';
// import { PositionPage } from './Pool/PositionPage';
// import RemoveLiquidityV3 from './RemoveLiquidity/V3';
// import {
//   OpenClaimAddressModalAndRedirectToSwap,
//   RedirectPathToSwapOnly,
//   RedirectToSwap
// } from './Swap/redirects';

import Swap from './pages/Trade/Swap';
import Navbar from './components/Navbar';
import ColorfulBackground from './components/ColorfulBackground/ColorfulBackground';
import Pool from 'pages/Pool/Pool';
import { PositionPage } from 'pages/Pool/PositionPage';
import { RedirectDuplicateTokenIds } from 'pages/AddLiquidity/redirects';
import AddLiquidity from 'pages/AddLiquidity';
import RemoveLiquidityV3 from 'pages/RemoveLiquidity/V3';
import MobileBottomBar from 'components/MobileBottomBar';
import Bridge from 'pages/Trade/Bridge';
import Trade from 'pages/Trade';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    padding-top: 80px;
  `};
`;

function getCurrentPageFromLocation(
  locationPathname: string
): PageName | undefined {
  switch (locationPathname) {
    case '/swap':
      return PageName.SWAP_PAGE;
    case '/vote':
      return PageName.VOTE_PAGE;
    case '/pool':
      return PageName.POOL_PAGE;
    case '/tokens':
      return PageName.TOKENS_PAGE;
    default:
      return undefined;
  }
}

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
);

const App = () => {
  const isLoaded = useFeatureFlagsIsLoaded();

  const { pathname } = useLocation();
  const currentPage = getCurrentPageFromLocation(pathname);
  const isDarkMode = useIsDarkMode();
  const isExpertMode = useIsExpertMode();
  const [scrolledState, setScrolledState] = useState(false);

  useAnalyticsReporter();

  const scrollListener = (e: Event) => {
    if (window.scrollY > 0) {
      setScrolledState(true);
    } else {
      setScrolledState(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrolledState(false);
  }, [pathname]);

  useEffect(() => {
    sendAnalyticsEvent(EventName.APP_LOADED);
    user.set(CustomUserProperties.USER_AGENT, navigator.userAgent);
    user.set(CustomUserProperties.BROWSER, getBrowser());
    user.set(
      CustomUserProperties.SCREEN_RESOLUTION_HEIGHT,
      window.screen.height
    );
    user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width);
    getCLS(({ delta }: Metric) =>
      sendAnalyticsEvent(EventName.WEB_VITALS, {
        cumulative_layout_shift: delta
      })
    );
    getFCP(({ delta }: Metric) =>
      sendAnalyticsEvent(EventName.WEB_VITALS, {
        first_contentful_paint_ms: delta
      })
    );
    getFID(({ delta }: Metric) =>
      sendAnalyticsEvent(EventName.WEB_VITALS, { first_input_delay_ms: delta })
    );
    getLCP(({ delta }: Metric) =>
      sendAnalyticsEvent(EventName.WEB_VITALS, {
        largest_contentful_paint_ms: delta
      })
    );
  }, []);

  useEffect(() => {
    user.set(CustomUserProperties.DARK_MODE, isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    user.set(CustomUserProperties.EXPERT_MODE, isExpertMode);
  }, [isExpertMode]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
  }, []);

  return (
    <>
      <DarkModeQueryParamReader />
      <ApeModeQueryParamReader />
      <ColorfulBackground />
      <Trace page={currentPage}>
        <Navbar />
        <MobileBottomBar />
        <BodyWrapper>
          {/* <Popups />
        <Polling /> */}
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                {/* <Route path="send" element={<RedirectPathToSwapOnly />} /> */}
                {/* <Route path="swap/:outputCurrency" element={<RedirectToSwap />} /> */}
                <Route path="trade" element={<Trade />}>
                  <Route path="swap" element={<Swap />} />
                  <Route path="bridge" element={<Bridge />} />
                </Route>
                <Route path="pool" element={<Pool />} />

                <Route path="pool/:tokenId" element={<PositionPage />} />

                <Route path="add" element={<RedirectDuplicateTokenIds />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                </Route>

                <Route path="increase" element={<AddLiquidity />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                </Route>

                <Route path="remove/:tokenId" element={<RemoveLiquidityV3 />} />

                <Route path="*" element={<Navigate to="trade/swap" />} />
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
        </BodyWrapper>
      </Trace>
    </>
  );
};

export default App;
