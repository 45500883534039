import styled from 'styled-components/macro';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { animated, useTransition } from 'react-spring';
import { ReactNode } from 'react';

const StyledSemiModalOverlay = styled(animated(DialogOverlay))`
  &[data-reach-dialog-overlay] {
    position: fixed;
    z-index: 2;
    background-color: ${({ theme }) =>
      theme.isDark
        ? `${theme.oneExNeutral2}B3` // alpha 70%
        : `${theme.oneExNeutral4}B3`}; // alpha 70%
    inset: 0;
  }
`;

const SemiModal = ({
  children,
  isOpen,
  onDismiss
}: {
  children: ReactNode;
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const fadeTransition = useTransition(isOpen, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return (
    <>
      {fadeTransition(
        ({ opacity }, item) =>
          item && (
            <StyledSemiModalOverlay
              style={{
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })
              }}
              onDismiss={onDismiss}
            >
              <DialogContent aria-label="dialog">{children}</DialogContent>
            </StyledSemiModalOverlay>
          )
      )}
    </>
  );
};

export default SemiModal;
