import { Theme, vars } from 'nft/css/sprinkles.css';

export const darkTheme: Theme = {
  colors: {
    accentFailure: vars.color.red300,
    accentFailureSoft: 'rgba(253, 118, 107, 0.12)',
    accentAction: vars.color.green1exAlt,
    accentActionSoft: '#07A36F',

    explicitWhite: '#FFFFFF',
    green: vars.color.green200,
    gold: vars.color.gold200,
    violet: vars.color.violet200,

    backgroundFloating: '0000000C',
    backgroundInteractive: vars.color.gray1exLight2,
    background1exGreenAlt2: vars.color.green1exAlt2,
    backgroundModule: vars.color.gray1ex,
    backgroundOutline: `rgba(153,161,189,0.24)`,
    backgroundSurface: vars.color.gray1ex,
    backgroundBackdrop: '#000',

    modalBackdrop:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',

    stateOverlayHover: `rgba(153,161,189,0.08)`,

    textPrimary: '#FFFFFF',
    textSecondary: vars.color.gray300,
    textTertiary: vars.color.gray500,

    // 1ex colors

    // Blur
    oneExBlur1: '#D6225F',
    oneExBlur2: '#22D3D3',

    // Neutral Opacity
    oneExNeutralOpacity: '#28292966',

    // Neutral
    oneExNeutral1: '#272829',
    oneExNeutral2: '#3C3D3D',
    oneExNeutral3: '#505152',
    oneExNeutral4: '#C7C7C7',
    oneExNeutral5: '#E0E0E0',
    oneExNeutral6: '#F5F5F5',

    // Primary
    oneExPrimaryMain: '#29A3A3',
    oneExPrimaryMainLight: '#22D3D3',
    oneExPrimaryLow: '#293C3C',
    oneExPrimaryHigh: '#B8E5E5',

    // Secondary
    oneExSecondaryMain: '#E0AA51',
    oneExSecondaryLow: '#524837'
  },
  shadows: {
    menu: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    genieBlue: '0 4px 16px 0 rgba(70, 115, 250, 0.4)',
    elevation: '0px 4px 16px rgba(70, 115, 250, 0.4)',
    tooltip: '0px 4px 16px rgba(255, 255, 255, 0.2)',
    deep: '12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32)',
    shallow:
      '4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12)'
  },
  opacity: {
    hover: '0.6',
    pressed: '0.4'
  }
};
