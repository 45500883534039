import { Currency, TradeType } from '@uniswap/sdk-core';
import { sendEvent } from 'components/analytics';
import { LoadingOpacityContainer } from 'components/Loader/styled';
import { RowFixed } from 'components/Row';
import { InterfaceTrade } from 'state/routing/types';
import styled from 'styled-components/macro';

import { ReactComponent as GasIcon } from '../../assets/images/gas-icon.svg';

const GasWrapper = styled(RowFixed)`
  border-radius: 8px;
  padding: 4px 6px;
  height: 24px;
  color: ${({ theme }) => theme.oneExNeutral5};
  /* background-color: ${({ theme }) => theme.deprecated_bg1}; */
  font-size: 14px;
  font-weight: 500;
  user-select: none;
`;
const StyledGasIcon = styled(GasIcon)`
  margin-right: 4px;
  height: 14px;
  & > * {
    stroke: ${({ theme }) => theme.deprecated_text3};
  }
`;

export default function GasEstimateBadge({
  trade,
  loading
}: {
  trade: InterfaceTrade<Currency, Currency, TradeType> | undefined | null; // dollar amount in active chain's stablecoin
  loading: boolean;
  showRoute?: boolean; // show route instead of gas estimation summary
  disableHover?: boolean;
}) {
  const formattedGasPriceString = trade?.gasUseEstimateUSD
    ? trade.gasUseEstimateUSD.toFixed(2) === '0.00'
      ? '<$0.01'
      : '$' + trade.gasUseEstimateUSD.toFixed(2)
    : undefined;

  return (
    <LoadingOpacityContainer $loading={loading}>
      <GasWrapper>
        <StyledGasIcon />
        {formattedGasPriceString ?? null}
      </GasWrapper>
    </LoadingOpacityContainer>
  );
}
