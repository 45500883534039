// import { useEffect, useRef } from 'react';
// import { useIsDarkMode } from 'state/user/hooks';
// import styled from 'styled-components/macro';

// const StyledChart = styled.div`
//   height: 30rem;
//   width: 51rem;
//   border-radius: 1rem;
//   overflow: hidden;
//   /* background-color: #2f2d42; */

//   @media only screen and (max-width: ${({ theme }) =>
//       `${theme.breakpoint.sm}px`}) {
//     width: 100%;
//     position: fixed;
//     left: 0;
//     right: 0;
//     bottom: 56px;
//     border-radius: 12px 12px 0 0;
//   }
// `;

// let tvScriptLoadingPromise: any;

const TradingViewChart = () => {
  //   const isDark = useIsDarkMode();

  //   const onLoadScriptRef = useRef<() => void>();

  //   useEffect(() => {
  //     const createWidget = (): void => {
  //       if (
  //         document.getElementById('tradingview_chart') &&
  //         'TradingView' in window &&
  //         typeof window.TradingView !== 'undefined'
  //       ) {
  //         new window.TradingView.widget({
  //           autosize: true,
  //           symbol: 'UNISWAP3ETH:DAIUSDC',
  //           interval: 'D',
  //           //   timezone: 'Etc/UTC',
  //           theme: isDark ? 'dark' : 'light',
  //           style: '3',
  //           locale: 'en',
  //           hide_top_toolbar: true,
  //           hide_side_toolbar: true,
  //           hidevolume: true,
  //           toolbar_bg: '#eb062c',
  //           //   enable_publishing: false,
  //           //   allow_symbol_change: true,
  //           container_id: 'tradingview_chart',
  //           overrides: {
  //             'paneProperties.background': '#191919',
  //             'paneProperties.backgroundType': 'solid'
  //           }
  //         });
  //       }
  //     };

  //     onLoadScriptRef.current = createWidget;

  //     if (!tvScriptLoadingPromise) {
  //       tvScriptLoadingPromise = new Promise(resolve => {
  //         const script = document.createElement('script');
  //         script.id = 'tradingview-widget-loading-script';
  //         script.src = 'https://s3.tradingview.com/tv.js';
  //         script.type = 'text/javascript';
  //         script.onload = resolve;

  //         document.head.appendChild(script);
  //       });
  //     }

  //     tvScriptLoadingPromise.then(
  //       () => onLoadScriptRef.current && onLoadScriptRef.current()
  //     );

  //     return () => (onLoadScriptRef.current = undefined);
  //   }, [isDark]);

  return (
    <div className="tradingview-widget-container">
      {/* <StyledChart id="tradingview_chart" /> */}
      {/* <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/symbols/NASDAQ-AAPL/"
          rel="noopener"
          target="_blank"
        >
          <span className="blue-text">AAPL stock chart</span>
        </a>{' '}
        by TradingView
      </div> */}
    </div>
  );
};

export default TradingViewChart;
