import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledNavbarItem = styled(NavLink)`
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.oneExNeutral4};

  &.active {
    /* border-bottom: 2px solid ${({ theme }) => theme.oneExSecondaryMain}; */
    color: ${({ theme }) => theme.oneExSecondaryMain};
  }
`;

const NavbarLink = ({
  href,
  children
}: {
  href: string;
  children: ReactNode;
}) => {
  return <StyledNavbarItem to={href}>{children}</StyledNavbarItem>;
};

export default NavbarLink;
