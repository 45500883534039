import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import SemiModal from 'components/SemiModal';
import { useModalIsOpen, useToggleTransactions } from 'state/application/hooks';
import { ApplicationModal } from 'state/application/reducer';
import { useAllTransactions } from 'state/transactions/hooks';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from 'state/hooks';
import { clearAllTransactions } from 'state/transactions/reducer';
import { useCallback, useMemo } from 'react';
import { Fraction, TradeType } from '@uniswap/sdk-core';
import { useCurrency } from 'hooks/Tokens';
import JSBI from 'jsbi';
import { TransactionState } from 'components/AccountDetailsV2';
import CurrencyIdLogoView from 'components/AccountDetailsV2/CurrencyIdLogoView';

import {
  ExactInputSwapTransactionInfo,
  ExactOutputSwapTransactionInfo,
  TransactionDetails,
  TransactionType
} from 'state/transactions/types';
import { useIsMobile } from 'nft/hooks';

const StyledTransactions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  overflow-y: auto;
  max-height: 200px;

  @media only screen and (max-width: ${({ theme }) =>
      `${theme.breakpoint.sm}px`}) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 56px;
    background-color: ${({ theme }) => theme.oneExNeutral1};
    border-radius: 12px 12px 0 0;
    padding: 16px;
    max-height: 70vh;
  }
`;

const StyledTransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const StyledTransactionsHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 32px;
  border-radius: 8px;
  justify-items: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.oneExNeutral4};
  background-color: ${({ theme }) =>
    theme.isDark ? '#28292966' : '#FFFFFF66'};
`;

const StyledTransactionRow = styled(StyledTransactionsHeader)`
  font-size: 12px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.oneExNeutral2};
`;

const StyledTransactionCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.oneExNeutral2};
`;

const StyledTransactionCardPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
`;

const StyledTransactionCardItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: center;
`;

const StyledDivider = styled.div`
  margin: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.oneExNeutral3};
`;

const StyledTransactionState = styled.div<{ transactionState: number }>`
  color: ${({ transactionState }) =>
    transactionState === 1 ? '#07A36F' : '#AB29CC'};
`;

const Transactions = ({ isMobile }: { isMobile: boolean }) => {
  const allTransactions = useAllTransactions();
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const transactionGroupsInformation = [];

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);

  const [confirmed, pending] = useMemo(() => {
    const confirmed: Array<TransactionDetails> = [];
    const pending: Array<TransactionDetails> = [];

    const sorted = Object.values(allTransactions).sort(
      (a, b) => b.addedTime - a.addedTime
    );
    const swapTransactions = sorted.filter(
      transaction => transaction.info.type === TransactionType.SWAP
    );
    swapTransactions.forEach(swapTransaction =>
      swapTransaction.receipt
        ? confirmed.push(swapTransaction)
        : pending.push(swapTransaction)
    );

    return [confirmed, pending];
  }, [allTransactions]);

  if (pending.length) transactionGroupsInformation.push(...pending);
  if (confirmed.length) transactionGroupsInformation.push(...confirmed);

  const isRecentTransactionsOpen = useModalIsOpen(
    ApplicationModal.TRANSACTIONS
  );
  const toggleTransactions = useToggleTransactions();

  const getRawAmounts = (
    info: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo
  ): { rawAmountFrom: string; rawAmountTo: string } => {
    return info.tradeType === TradeType.EXACT_INPUT
      ? {
          rawAmountFrom: info.inputCurrencyAmountRaw,
          rawAmountTo: info.expectedOutputCurrencyAmountRaw
        }
      : {
          rawAmountFrom: info.expectedInputCurrencyAmountRaw,
          rawAmountTo: info.outputCurrencyAmountRaw
        };
  };

  const formatAmount = (
    amountRaw: string,
    decimals: number,
    sigFigs: number
  ): string =>
    new Fraction(
      amountRaw,
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
    ).toSignificant(sigFigs);

  const FormattedCurrencyAmount = ({
    info
  }: {
    info: ExactOutputSwapTransactionInfo | ExactInputSwapTransactionInfo;
  }) => {
    const { rawAmountFrom, rawAmountTo } = getRawAmounts(info);

    const inputCurrency = useCurrency(info.inputCurrencyId);
    const outputCurrency = useCurrency(info.outputCurrencyId);

    return inputCurrency && outputCurrency ? (
      <div>
        {formatAmount(rawAmountFrom, inputCurrency.decimals, /* sigFigs= */ 6)}
        {' - '}
        {formatAmount(rawAmountTo, outputCurrency.decimals, /* sigFigs= */ 6)}
      </div>
    ) : null;
  };

  const TransactionDetailsContent = ({
    transactionDetails
  }: {
    transactionDetails: TransactionDetails;
  }) => {
    const isMobile = useIsMobile();

    const tx = transactionDetails;
    const { info, receipt } = tx;

    const transactionState = useMemo(() => {
      const pending = !receipt;
      const success =
        !pending &&
        tx &&
        (receipt?.status === 1 || typeof receipt?.status === 'undefined');
      const transactionState = pending
        ? TransactionState.Pending
        : success
        ? TransactionState.Success
        : TransactionState.Fail;

      return transactionState;
    }, [receipt, tx]);

    if (isMobile) {
      return (
        <StyledTransactionCard>
          <StyledTransactionCardPart>
            <StyledTransactionCardItem>
              <Trans>From</Trans>
              <CurrencyIdLogoView info={info} variant="input" />
            </StyledTransactionCardItem>
            <StyledTransactionCardItem>
              <Trans>To</Trans>
              <CurrencyIdLogoView info={info} variant="output" />
            </StyledTransactionCardItem>
            <StyledTransactionCardItem>
              <Trans>Status</Trans>
              <StyledTransactionState transactionState={transactionState}>
                {transactionState === 0 ? (
                  <Trans>Pending</Trans>
                ) : transactionState === 1 ? (
                  <Trans>Success</Trans>
                ) : (
                  <Trans>Fail</Trans>
                )}
              </StyledTransactionState>
            </StyledTransactionCardItem>
          </StyledTransactionCardPart>
          <StyledDivider />
          <StyledTransactionCardPart>
            <StyledTransactionCardItem>
              <Trans>Amount</Trans>
              <FormattedCurrencyAmount
                info={
                  info as
                    | ExactOutputSwapTransactionInfo
                    | ExactInputSwapTransactionInfo
                }
              />
            </StyledTransactionCardItem>
            <StyledTransactionCardItem>
              <Trans>Date</Trans>
              <div>
                {new Intl.DateTimeFormat('en-US', {
                  dateStyle: 'short',
                  timeStyle: 'short',
                  hourCycle: 'h24'
                }).format(transactionDetails.addedTime)}
              </div>
            </StyledTransactionCardItem>
          </StyledTransactionCardPart>
        </StyledTransactionCard>
      );
    }

    return (
      <StyledTransactionRow>
        <CurrencyIdLogoView info={info} variant="input" />
        <CurrencyIdLogoView info={info} variant="output" />
        <div>
          {new Intl.DateTimeFormat('en-US', {
            dateStyle: 'short',
            timeStyle: 'short',
            hourCycle: 'h24'
          }).format(transactionDetails.addedTime)}
        </div>
        <FormattedCurrencyAmount
          info={
            info as
              | ExactOutputSwapTransactionInfo
              | ExactInputSwapTransactionInfo
          }
        />
        <StyledTransactionState transactionState={transactionState}>
          {transactionState === 0 ? (
            <Trans>Pending</Trans>
          ) : transactionState === 1 ? (
            <Trans>Success</Trans>
          ) : (
            <Trans>Fail</Trans>
          )}
        </StyledTransactionState>
      </StyledTransactionRow>
    );
  };

  const content = (
    <StyledTransactions>
      <div>
        <Trans>Recent Transactions</Trans>
      </div>
      <StyledTransactionsContainer>
        {!isMobile && (
          <StyledTransactionsHeader>
            <div>
              <Trans>From</Trans>
            </div>
            <div>
              <Trans>To</Trans>
            </div>
            <div>
              <Trans>Date</Trans>
            </div>
            <div>
              <Trans>Amount</Trans>
            </div>
            <div>
              <Trans>Status</Trans>
            </div>
          </StyledTransactionsHeader>
        )}
        {transactionGroupsInformation.length > 0 ? (
          <>
            {transactionGroupsInformation.map(transactionDetails => (
              <TransactionDetailsContent
                transactionDetails={transactionDetails}
                // eslint-disable-next-line react/prop-types
                key={transactionDetails.hash}
              />
            ))}
          </>
        ) : (
          <div>
            <Trans>Your transactions will appear here</Trans>
          </div>
        )}
      </StyledTransactionsContainer>
    </StyledTransactions>
  );

  return isMobile ? (
    <SemiModal isOpen={isRecentTransactionsOpen} onDismiss={toggleTransactions}>
      {content}
    </SemiModal>
  ) : (
    content
  );
};

export default Transactions;
